
export default {
    name: "LayeredParallax",
    props: {
        // urls of image resources for [layer1:layerN] where layer1 is the background layer and
        // layerN is the foreground layer
        layers: {
            type: Array,
            default: () => [],
        },
        // 3d offsets of the layers
        layerTransforms: {
            type: Array,
            default: () => [],
        },
        width: {
            type: Number,
            default: null,
        },
        height: {
            type: Number,
            default: null,
        },
        /** Makes parallax relative to component position, not page top */
        relative: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            componentPos: 0,
            absolutePos: 0,
        }
    },
    computed: {
        styles() {
            return this.layers.map((url, i) =>
                this.getStyle(url, this.layerTransforms[i], this.absolutePos)
            )
        },
        containerStyle() {
            return {
                width: this.width ? this.width + "px" : undefined,
                height: this.height ? this.height + "px" : undefined,
            }
        },
        pivot() {
            return (
                this.layerTransforms.reduce((acc, x) => acc + x) /
                this.layerTransforms.length
            )
        },
    },
    mounted() {
        this.componentPos = window.scrollY
        window.addEventListener("scroll", this.onScroll)
    },
    destroyed() {
        window.removeEventListener("scroll", this.onScroll)
    },
    methods: {
        onScroll() {
            if (this.relative) {
                let viewportOffset = this.$refs.parallax.getBoundingClientRect()
                this.absolutePos = viewportOffset.top
                /* Component position relative to window top */
            } else {
                this.absolutePos = window.scrollY
            }
        },
        getStyle(url, t, pos) {
            let movement = -pos * (t - this.pivot)
            let translate3d = "translate3d(0, " + movement + "px, 0);"
            let t1 = "-webkit-transform: " + translate3d
            let t2 = "-moz-transform: " + translate3d
            let t3 = "-ms-transform: " + translate3d
            let t4 = "-o-transform: " + translate3d
            return (
                'background-image: url("' +
                url +
                '") !important; ' +
                t1 +
                t2 +
                t3 +
                t4
            )
        },
    },
}
