
import LayeredParallax from "@/components/base/LayeredParallax"
import { mapGetters } from "vuex"
export default {
    name: "JobFairHeader",
    components: { LayeredParallax },
    data: () => ({
        parallaxLayerTransforms: [
            1.5, 1.55, 1.58, 1.6, 1.5, 1.7, 2, 2.2, 2.25, 2.3,
        ],
    }),
    computed: {
        ...mapGetters("search", ["selectedCategory"]),
        parallaxLayers() {
            if (this.$breakpoint.mdAndUp) {
                return [
                    require("@/assets/images/fair/altmuehlfranken/parallax/l10.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l9.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l8.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l7.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l6.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l5.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l4.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l3.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l2.svg"),
                    require("@/assets/images/fair/altmuehlfranken/parallax/l1.svg"),
                ]
            }
            return [
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l10.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l9.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l8.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l7.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l6.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l5.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l4.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l3.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l2.svg"),
                require("@/assets/images/fair/altmuehlfranken/parallax/mobile/l1.svg"),
            ]
        },
        parallaxHeight() {
            if (this.$breakpoint.xsOnly) return 280
            if (this.$breakpoint.smAndDown) return 530
            if (this.$breakpoint.lgAndDown) return 640
            return 720
        },
        isOverviewPage() {
            return this.selectedCategory == "Alles"
        },
        seoHeadline() {
            switch (this.selectedCategory) {
                case "Unternehmen":
                    return "Unsere <strong>Ausbildungsbetriebe</strong><br /> in <strong>Altmühlfranken</strong>"
                case "Praktikum":
                    return "Freie <strong>Schülerpraktika</strong><br /> in <strong>Altmühlfranken</strong>"
                case "Ausbildung":
                    return "Freie <strong>Ausbildungsstellen</strong><br /> in <strong>Altmühlfranken</strong>"
                case "Neuigkeiten":
                    return "<strong>Neuigkeiten & Highlights</strong> in <strong>Altmühlfranken</strong>"
                default:
                    return "Meine Zukunft<br /><strong>altmühlfranken</strong>"
            }
        },
    },
}
